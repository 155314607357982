import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faFacebook, faTwitter, faWhatsapp} from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import axios from '../../../axios-base';

const ShareButton = (props) => {
    const { voteCount, threshold1, threshold2, fundingIncrement1, fundingIncrement2, projectId, shareType } = props;
    let shareText;
    let shareUrl = window.location.href;

    if(voteCount < threshold1){
        shareText = encodeURIComponent(`Uns fehlen noch ${threshold1 - voteCount} Stimmen für eine Förderung von ${fundingIncrement1} Euro. Hilf uns das Förderungsziel zu erreichen und stimme jetzt für uns ab`);
    } else if (voteCount >= threshold1 && voteCount < threshold2 ) {
        shareText = encodeURIComponent(`Uns fehlen noch ${threshold2 - voteCount} Stimmen für eine Förderung von ${fundingIncrement2} Euro. Hilf uns das Förderungsziel zu erreichen und stimme jetzt für uns ab`);
    } else {
        shareUrl = window.location.origin + "/projekte/";
        shareText = encodeURIComponent(`Wir haben es geschafft und das Förderziel erreicht! Hier gibt es noch andere tolle Projekte denen wir helfen können ihr Förderziel zu erreichen`);
    }

    const handleShare = () => {
        // Make a PATCH request to your backend to increment the share count
        axios.patch(`/achievements/${projectId}/${shareType}Shares`)
            .then(response => {
                // Handle success response if needed
                console.log(response.data);
            })
            .catch(error => {
                // Handle error response if needed
                console.error('Error:', error);
            });

        // Call the existing sharing functionality based on shareType
        switch (shareType) {
        case 'email':
                window.open(`mailto:?subject=Jetzt für uns abstimmen!&body=${shareText}: ${shareUrl}`, '_blank');
            break;
        case 'facebook':
                window.open(`https://www.facebook.com/sharer/sharer.php?u=${shareUrl}`, '_blank');
            break;
        case 'twitter':
                window.open(`https://twitter.com/share?url=${shareUrl}&text=${shareText}`, '_blank');
            break;
        case 'whatsApp':
                window.open(`whatsapp://send?text=${shareText}%20${shareUrl}`, '_blank');
            break;
        default:
            break;
    }
    };

    let button;
    switch (shareType) {
        case 'email':
            button = (
                <button
                    onClick={handleShare}
                    className="btn-outline-email btn rounded d-block w-100">
                    <FontAwesomeIcon icon={faEnvelope} size="lg" className="pe-1"/>Mailen
                </button>
            );
            break;
        case 'facebook':
            button = (
                <button
                    onClick={handleShare}
                    className="btn-outline-facebook btn rounded d-block w-100">
                    <FontAwesomeIcon icon={faFacebook} size="lg" className="pe-1"/>Teilen
                </button>
            );
            break;
        case 'twitter':
            button = (
                <button
                    onClick={handleShare}
                    className="btn-outline-twitter btn rounded d-block w-100">
                    <FontAwesomeIcon icon={faTwitter} size="lg" className="pe-1"/>Twittern
                </button>
            );
            break;
        case 'whatsApp':
            button = (
                <button
                    onClick={handleShare}
                    className="btn-outline-whatsapp btn rounded d-block w-100">
                    <FontAwesomeIcon icon={faWhatsapp} size="lg" className="pe-1"/>Teilen
                </button>
            );
            break;
        default:
            button = (
                <span>Kein Dienst definiert</span>
            );
            break;
    }

    return button;
};

export default ShareButton;
