import React from 'react';
import classes from './ProjectListItem.module.scss';
import {Card, CardBody, CardTitle, CardSubtitle, CardText, Col, Row, CardFooter} from 'reactstrap';
import ProgressBar from "../../../components/UI/ProgressBar/ProgressBar";
import Badge from '../../../components/UI/Badges/Badge';

const projectListItem = (props) => {
    const {achievements} = props;

    return (
        <Col className={"mb-4 " + props.display} key={props._id}>
            <Card className="h-100 anl__project-list--item">
                <div className={classes.CardImageContainer}>
                    <div className={classes.CardImage} style={{ backgroundImage: `url(${props.image})` }} />
                </div>
                <CardBody className="d-flex flex-column">
                    <CardTitle tag="h2">{props.name}</CardTitle>
                    <CardSubtitle tag="h6" className="mb-2 text-muted">{props.title}</CardSubtitle>
                    <CardText>{props.shortDescription}</CardText>
                    <ProgressBar
                        bubble={false}
                        projectBalance={props.projectBalance}
                        threshold1={props.threshold1}
                        threshold2={props.threshold2}
                        voteCount={props.voteCount}
                        fundingIncrement1={props.fundingIncrement1}
                        fundingIncrement2={props.fundingIncrement2}
                        phases={props.phases}
                        cssClasses={'mt-auto'}
                    />
                    {props.children}
                </CardBody>
                <CardFooter className={`p-0 ${classes.CardFooter}`}>
                    <Row className="m-0 top-0 justify-content-end">
                        <Badge type="isReferral" isAchieved={achievements.some(ach => ach.type === 'isReferral' && ach.isAchieved)} />
                        <Badge type="hasReferred" isAchieved={achievements.some(ach => ach.type === 'hasReferred' && ach.isAchieved)} />
                        <Badge type="emailShares" isAchieved={achievements.some(ach => ach.type === 'emailShares' && ach.isAchieved)} />
                        <Badge type="twitterShares" isAchieved={achievements.some(ach => ach.type === 'twitterShares' && ach.isAchieved)} />
                        <Badge type="facebookShares" isAchieved={achievements.some(ach => ach.type === 'facebookShares' && ach.isAchieved)} />
                        <Badge type="whatsAppShares" isAchieved={achievements.some(ach => ach.type === 'whatsAppShares' && ach.isAchieved)} />
                        {/* Add more BadgeComponent instances as needed for other badge types */}
                    </Row>
                </CardFooter>
            </Card>
        </Col>
    );
};

export default projectListItem;
